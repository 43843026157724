import { Injectable } from '@angular/core';
import { Subject, ReplaySubject, Observable } from 'rxjs';
import { Router, NavigationStart, ResolveStart } from '../../../../node_modules/@angular/router';
import { ThemePalette } from '@angular/material/core';
import { LanguageService } from 'src/app/services/language.service';

export enum ControlMode {
    add,
    edit,
    hide,
}

export class FixedButton {
    private _buttonClickedSubject: Subject<void>;
    buttonClickedObservable: Observable<void>;
    isHidden: boolean;
    policies: string[];
    isLoading: boolean = false;
    isDisabled: boolean = false;
    tooltip: string;

    options = {
        buttonColor: 'primary',
        spinnerColor: 'accent',
        icon: 'favorite',
    };

    constructor(icon: string, color: ThemePalette = 'primary', tooltip: string = null, isHidden = false) {
        this.options.icon = icon;
        this.isHidden = isHidden;
        this.options.buttonColor = color;
        this._buttonClickedSubject = new Subject<void>();
        this.buttonClickedObservable = this._buttonClickedSubject.asObservable();
        this.tooltip = tooltip;
    }

    setTooltipMessage(tooltip: string): FixedButton {
        this.tooltip = tooltip;
        return this;
    }

    disable(loading = true) {
        if (loading) {
            this.isLoading = true;
        } else {
            this.isDisabled = true;
        }
    }

    enable() {
        this.isLoading = false;
        this.isDisabled = false;
        this.isLoading = false;
    }
    click() {
        if (this.isLoading || this.isLoading) {
            return;
        }
        this._buttonClickedSubject.next();
    }

    hide() {
        this.isHidden = true;
    }
}

@Injectable({
    providedIn: 'root',
})
export class FixedFormControlsService {
    showLanguageButtonObservable: ReplaySubject<boolean> = new ReplaySubject<boolean>();
    fixedButtons: FixedButton[] = [];

    constructor(router: Router, public languageService: LanguageService) {
        // every component that wishes to use this needs to set it up, this way no components will need to do a tear down

        if (router.events) {
            router.events.subscribe((event) => {
                if (event instanceof ResolveStart) {
                    this.showLanguageButtonObservable.next(false);
                    this.fixedButtons = [];
                }
            });
        }
    }

    getEventClickObservable(): Observable<void> {
        return this.fixedButtons[0].buttonClickedObservable;
    }

    setupSingleAddButton(icon: string = 'add', policies: string[] = [], tooltip: string = null): FixedButton {
        if (icon === 'add' && tooltip === null) {
            tooltip = 'CREATE';
        }

        return this._setupFixedButton(icon, 'primary', policies, tooltip);
    }

    setupSingleEditButton(icon: string = 'save', policies: string[] = [], color: ThemePalette = 'accent', tooltip: string = null): FixedButton {
        if (icon === 'save' && tooltip === null) {
            tooltip = 'SAVE';
        }
        return this._setupFixedButton(icon, color, policies, tooltip);
    }

    showLanguageButton(value: boolean) {
        this.showLanguageButtonObservable.next(value);
    }

    disableAllButtonsAndSetOneAsLoading(fixedButtonToSetAsLoading: FixedButton) {
        for (let fixedButton of this.fixedButtons) {
            if (fixedButton === fixedButtonToSetAsLoading) {
                fixedButton.disable();
            } else {
                fixedButton.disable(false);
            }
        }
    }

    enableAllButtons() {
        for (let fixedButton of this.fixedButtons) {
            fixedButton.enable();
        }
    }

    showButton(icon: string) {
        for (let fixedButton of this.fixedButtons) {
            if (fixedButton.options.icon === icon) {
                fixedButton.isHidden = false;
            }
        }
    }

    hideButton(icon: string) {
        for (let fixedButton of this.fixedButtons) {
            if (fixedButton.options.icon === icon) {
                fixedButton.hide();
            }
        }
    }

    hideAllButtons() {
        for (let fixedButton of this.fixedButtons) {
            fixedButton.hide();
        }
    }

    setupSingleDownloadButton(icon: string = 'download', policies: string[] = [], tooltip: string = null): FixedButton {
        if (icon === 'download' && tooltip === null) {
            tooltip = 'DOWNLOAD';
        }

        return this._setupFixedButton(icon, 'accent', policies, tooltip);
    }

    setupSingleRemoveButton(icon: string = 'delete', policies: string[] = [], tooltip: string = null): FixedButton {
        if (icon === 'delete' && tooltip == null) {
            tooltip = 'DELETE'
        }
        return this._setupFixedButton(icon, 'primary', policies, tooltip)
    }

    private _setupFixedButton(icon: string, color: ThemePalette, policies: string[], tooltip: string = null): FixedButton {
        const fixedButton = new FixedButton(icon, color, tooltip);
        fixedButton.policies = policies;
        this.fixedButtons.push(fixedButton);
        return fixedButton;
    }
}
