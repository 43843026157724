import { DisplayImgModule } from './shared/display-img/display-img.module';

import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AppComponent } from './app.component';
import { FooterModule } from './shared/footer/footer.module';
import { AddressFormModule } from './shared/address-form/address-form.module';
import { AddressDtoFormModule } from './shared/address-dto-form/address-dto-form.module';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AppRoutes, EmployeeAuthGuardCanActivate, ClientAuthGuardCanActivate, ERPOrderAuthGuardCanActivate } from './app.routing';
import { AccountManageService } from './services/accountManage.service';
import {
    AccountService,
    EmployeesService,
    ClientsService,
    ClientLeadsService,
    RolesService,
    ProductsService,
    ProductGroupsService,
    CategoriesService,
    MenusService,
    TabletCatalogService,
    ReferralCatalogService,
    AppointmentsService,
    BackgroundJobService,
    FreezersService,
    FreezerRegionsService,
    ImageService,
    DispatchedAppointmentsService,
    SalesRepsService,
    ManagementAppointmentsService,
    SalesRepAppointmentsService,
    AppointmentQualificationsService,
    VideoService,
    LoginPageManagementService,
    CallbackService,
    ServiceTicketsService,
    ClientNextDeliveryTaskService,
    SalesRepDashboardService,
    TMDashboardService,
    FederalDnclService,
    PhoneNumberPrefixService,
    CampaignsService,
    CreditTaskCampaignService,
    ScheduledJobsService,
    NotificationConfigurationService,
    ClientLeadSourcesService,
    RoutesService,
    CircuitOrderService,
    LocalDnclService,
    CallCenterConfigurationService,
    ScheduleManagementService,
    OptimoConfigService,
    BlanketOrderMgmtService,
    ErpIntegrationMgmtService,
} from './services/api.services';
import { API_BASE_URL } from './services/api.services';
import { CountriesService } from './services/countries.service';
import { NotifyService } from './services/notify.service';
import { EnumListService } from './services/enum-list.service';
import { TableContainerModule } from './shared/table-container/table-container.module';
import { LanguageService } from './services/language.service';
import { UserConfigurationService } from './services/user-configuration.service';
import { ApiConfigService } from './services/api-config.service';
import { NgxPermissionsModule } from 'ngx-permissions';
import { SimpleEntityCreateFormDialogService } from './shared/simple-entity-create-form-dialog/simple-entity-create-form-dialog.service';
import { SimpleEntityCreateFormDialogModule } from './shared/simple-entity-create-form-dialog/simple-entity-create-form-dialog.module';
import { ClientPortalNavbarModule } from './shared/client-portal-navbar/client-portal-navbar.module';
import { ConfirmDialogService } from './shared/confirm-dialog/confirm-dialog.service';
import { AdminModule } from './layouts/admin/admin.module';
import { ClientPortalModule } from './layouts/client-portal/client-portal.module';
import { NoteFormDialogService } from './shared/dialog/note-form-dialog/note-form-dialog.service';
import { NoteFormDialogModule } from './shared/dialog/note-form-dialog/note-form-dialog.module';
import { CalendarModule } from './shared/calendar/calendar.module';
import { ClientLeadNoteEventLoaderService } from './services/event-loaders/client-lead-note-event-loader.service';
import { FormContainerService } from './services/form-container.service';
import { FormDialogModule } from './shared/dialog/form-dialog/form-dialog.module';
import { PendingChangesGuard } from './shared/guards/canDeactivateGuard';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FileUploadDialogModule } from './shared/dialog/file-upload-dialog/file-upload-dialog.module';
import { FileUploadDialogService } from './shared/dialog/file-upload-dialog/file-upload-dialog.service';
import { PagingPersistService } from './services/paging-persistent.service';
import { SearchDialogService } from './shared/search-dialog/search-dialog.service';
import { LocalSearchTableModule } from './shared/search-dialog/local-search-table/local-search-table.module';
import { ServerSearchTableModule } from './shared/search-dialog/server-search-table/server-search-table.module';
import { ImgCropperDialogService } from './shared/dialog/img-cropper-dialog/img-cropper-dialog.service';
import { ImgCropperDialogModule } from './shared/dialog/img-cropper-dialog/img-cropper-dialog.module';
import { GooglePlacesModule } from './shared/directives/google-places/google-places.module';
import { OrderItemsServerSearchTableModule } from './shared/search-dialog/order-items-server-search-table/order-items-server-search-table.module';
import { AddToDncDialogModule } from './shared/dialog/add-to-dnc-dialog/add-to-dnc-dialog.module';
import { AddToDncDialogService } from './shared/dialog/add-to-dnc-dialog/add-to-dnc-dialog.service';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { ButtonListDialogService } from './shared/dialog/button-list-dialog/button-list-dialog.service';
import { ButtonListDialogModule } from './shared/dialog/button-list-dialog/button-list-dialog.module';
import { ProgressDialogService } from './shared/dialog/progress-dialog/progress-dialog.service';
import { ProgressDialogModule } from './shared/dialog/progress-dialog/progress-dialog.module';
import { AppointmentDispatchConfirmDialogService } from './shared/dialog/appointment-dispatch-confirm-dialog/appointment-dispatch-confirm-dialog.service';
import { AppointmentDispatchConfirmDialogModule } from './shared/dialog/appointment-dispatch-confirm-dialog/appointment-dispatch-confirm-dialog.module';
import { PastAppointmentsDisplayDialogModule } from './shared/dialog/past-appointments-display-dialog/past-appointments-display-dialog.module';
import { AppointmentQualificationDialogService } from './shared/dialog/appointment-qualification-dialog/appointment-qualification-dialog.service';
import { AppointmentQualificationDialogModule } from './shared/dialog/appointment-qualification-dialog/appointment-qualification-dialog.module';
import { ClientLeadNotesDialogService } from './admin-pages/client-leads/client-lead-notes-dialog/client-lead-notes-dialog.service';
import { ClientLeadNotesDialogModule } from './admin-pages/client-leads/client-lead-notes-dialog/client-lead-notes-dialog.module';
import { ImportReferencesDialogService } from './shared/dialog/import-references-dialog/import-references-dialog.service';
import { ImportReferencesDialogModule } from './shared/dialog/import-references-dialog/import-references-dialog.module';
import { AlphaNumericModule } from './shared/directives/alphanumeric/alphanumeric.module';
import { ProductReorderDialogService } from './shared/dialog/product-reorder-dialog/product-reorder-dialog.service';
import { ProductReorderDialogModule } from './shared/dialog/product-reorder-dialog/product-reorder-dialog.module';
import { CategoriesCacheService } from './services/categories-cache.service';
import { FindOrderReferencesDialogModule } from './shared/dialog/find-order-references-dialog/find-order-references-dialog.module';
import { FindOrderReferencesDialogService } from './shared/dialog/find-order-references-dialog/find-order-references-dialog.service';
import { CallbackNotesDialogModule } from './admin-pages/callbacks/callback-notes-dialog/callback-notes-dialog.module';
import { CallbackNotesDialogService } from './admin-pages/callbacks/callback-notes-dialog/callback-notes-dialog.service';
import { CategoryReorderDialogModule } from './shared/dialog/category-reorder-dialog/category-reorder-dialog.module';
import { CategorySelectorDialogModule } from './shared/dialog/category-selector-dialog/category-selector-dialog.module';
import { CategoryReorderDialogService } from './shared/dialog/category-reorder-dialog/category-reorder-dialog.service';
import { CategorySelectorDialogService } from './shared/dialog/category-selector-dialog/category-selector-dialog.service';
import { ChangeServiceTicketStatusDialogModule } from './shared/dialog/change-service-ticket-status-dialog/change-service-ticket-status-dialog.module';
import { ChangeServiceTicketStatusDialogService } from './shared/dialog/change-service-ticket-status-dialog/change-service-ticket-status-dialog.service';
import { NotesDialogModule } from './shared/dialog/common-notes-dialog/common-notes-dialog.module';
import { CallbackNoteFormDialogModule } from './shared/dialog/callback-note-form-dialog/callback-note-form-dialog.module';
import { CallbackNoteFormDialogService } from './shared/dialog/callback-note-form-dialog/callback-note-form-dialog.service';
import { DirectivesModule } from './shared/directives/directives.module';
import { DataSharingService } from './admin-pages/callbacks/data-sharing.service';
import { ServiceTicketNoteFormDialogModule } from './shared/dialog/service-ticket-note-form-dialog/service-ticket-note-form-dialog.module';
import { ServiceTicketNoteFormDialogService } from './shared/dialog/service-ticket-note-form-dialog/service-ticket-note-form-dialog.service';
import { ClientNextDeliveryNoteFormDialogService } from './admin-pages/client-next-delivery-task/client-next-delivery-note-form-dialog/client-next-delivery-note-form-dialog.service';
import { ClientNextDeliveryNoteFormDialogModule } from './admin-pages/client-next-delivery-task/client-next-delivery-note-form-dialog/client-next-delivery-note-form-dialog.module';
import { AppointmentTypeDialogModule } from './shared/dialog/appointment-type-dialog/appointment-type-dialog.module';
import { AppointmentTypeDialogService } from './shared/dialog/appointment-type-dialog/appointment-type-dialog.service';
import { ConfirmDialogModule } from './shared/confirm-dialog/confirm-dialog.module';
import { FixedFormControlsService } from './shared/fixed-form-controls/fixed-form-controls.service';
import { ClientNextDeliveryTaskAutomationDialogService } from './shared/dialog/client-next-delivery-task-automation-dialog/client-next-delivery-task-automation-dialog.service';
import { ClientNextDeliveryTaskAutomationDialogModule } from './shared/dialog/client-next-delivery-task-automation-dialog/client-next-delivery-task-automation-dialog.module';
import { ClientLeadSourcesListService } from './services/client-lead-sources-list-service';
// import { PushToOptimorouteDialogService } from './shared/dialog/push-to-optimoroute-dialog/push-to-optimoroute-dialog.service';
// import { PushToOptimorouteDialogModule } from './shared/dialog/push-to-optimoroute-dialog/push-to-optimoroute-dialog.module';
import { CircuitOrderBatchMoveDialogModule } from './shared/dialog/circuit-order-batch-move-dialog/circuit-order-batch-move-dialog.module';
import { provideEnvironmentNgxMask, NgxMaskDirective, NgxMaskPipe, IConfig } from 'ngx-mask';
import { ServiceTicketFormDialogService } from './shared/dialog/service-ticket-form-dialog/service-ticket-form-dialog.service';
import { ServiceTicketFormDialogModule } from './shared/dialog/service-ticket-form-dialog/service-ticket-form-dialog.module';
import { AddPhoneNumbersDialogService } from './shared/dialog/add-phone-numbers-dialog/add-phone-numbers-dialog.service';
import { AddPhoneNumbersDialogModule } from './shared/dialog/add-phone-numbers-dialog/add-phone-numbers-dialog.module';
import { ErpBlanketSalesOrderManagementModule } from './admin-pages/erp-blanket-sales-order-management/erp-blanket-sales-order-management.module';
import { BlanketOrderModificationPreviewDialogService } from './shared/dialog/blanket-order-modification-preview-dialog/blanket-order-modification-preview.dialog.service';
import { BlanketOrderModificationPreviewDialogModule } from './shared/dialog/blanket-order-modification-preview-dialog/blanket-order-modification-preview-dialog.module';
import { DeliveryDateChoiceDialogModule } from './shared/dialog/delivery-date-choice-dialog/delivery-date-choice-dialog.module';
import { DeliveryDateChoiceDialogService } from './shared/dialog/delivery-date-choice-dialog/delivery-date-choice-dialog.service';

export function getBaseUrl() {
    const baseUrl = document.getElementsByTagName('base')[0].href;
    return baseUrl.substring(0, baseUrl.length - 1);
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

const maskConfig: Partial<IConfig> = {
    validation: false,
};

@NgModule({
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        FormsModule,
        RouterModule.forRoot(AppRoutes, { enableTracing: false }),
        HttpClientModule,
        ClientPortalNavbarModule,
        FooterModule,
        AddressFormModule,
        AddressDtoFormModule,
        TableContainerModule,
        LocalSearchTableModule,
        ServerSearchTableModule,
        OrderItemsServerSearchTableModule,
        SimpleEntityCreateFormDialogModule,
        ConfirmDialogModule,
        ProgressDialogModule,
        NgxPermissionsModule.forRoot(),
        AdminModule,
        ClientPortalModule,
        NoteFormDialogModule,
        AddToDncDialogModule,
        AppointmentTypeDialogModule,
        CalendarModule,
        FormDialogModule,
        FileUploadDialogModule,
        ImgCropperDialogModule,
        GooglePlacesModule,
        ButtonListDialogModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        AppointmentDispatchConfirmDialogModule,
        PastAppointmentsDisplayDialogModule,
        AppointmentQualificationDialogModule,
        ClientLeadNotesDialogModule,
        ImportReferencesDialogModule,
        AlphaNumericModule,
        ProductReorderDialogModule,
        FindOrderReferencesDialogModule,
        CallbackNotesDialogModule,
        CategoryReorderDialogModule,
        CategorySelectorDialogModule,
        ChangeServiceTicketStatusDialogModule,
        ServiceTicketFormDialogModule,
        CallbackNoteFormDialogModule,
        DirectivesModule,
        NotesDialogModule,
        ServiceTicketNoteFormDialogModule,
        ClientNextDeliveryNoteFormDialogModule,
        DisplayImgModule,
        ClientNextDeliveryTaskAutomationDialogModule,
        //PushToOptimorouteDialogModule,
        CircuitOrderBatchMoveDialogModule,
        NgxMaskDirective,
        NgxMaskPipe,
        AddPhoneNumbersDialogModule,
        BlanketOrderModificationPreviewDialogModule,
        DeliveryDateChoiceDialogModule
    ],
    declarations: [AppComponent, AuthLayoutComponent],
    providers: [
        provideEnvironmentNgxMask(maskConfig),
        { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] },
        { provide: 'API_BASE_URL', useValue: getBaseUrl() },
        { provide: API_BASE_URL, useValue: getBaseUrl() },
        { provide: LOCALE_ID, useValue: 'en' },
        AccountManageService,
        AccountService,
        ClientsService,
        EmployeesService,
        CountriesService,
        NotifyService,
        EnumListService,
        ClientLeadSourcesListService,
        ERPOrderAuthGuardCanActivate,
        EmployeeAuthGuardCanActivate,
        ClientAuthGuardCanActivate,
        PendingChangesGuard,
        SearchDialogService,
        FixedFormControlsService,
        LanguageService,
        UserConfigurationService,
        ApiConfigService,
        SimpleEntityCreateFormDialogService,
        ConfirmDialogService,
        ClientLeadsService,
        ClientLeadSourcesService,
        NoteFormDialogService,
        AddToDncDialogService,
        AppointmentTypeDialogService,
        ClientLeadNoteEventLoaderService,
        FormContainerService,
        FileUploadDialogService,
        RolesService,
        PagingPersistService,
        ProductsService,
        ProductGroupsService,
        ReferralCatalogService,
        CategoriesService,
        MenusService,
        TabletCatalogService,
        ImgCropperDialogService,
        AppointmentsService,
        ButtonListDialogService,
        ProgressDialogService,
        BackgroundJobService,
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        FreezersService,
        FreezerRegionsService,
        FederalDnclService,
        ImageService,
        DispatchedAppointmentsService,
        SalesRepsService,
        ManagementAppointmentsService,
        SalesRepAppointmentsService,
        AppointmentDispatchConfirmDialogService,
        AppointmentQualificationsService,
        AppointmentQualificationDialogService,
        VideoService,
        LoginPageManagementService,
        ClientLeadNotesDialogService,
        ImportReferencesDialogService,
        ProductReorderDialogService,
        CategoriesCacheService,
        FindOrderReferencesDialogService,
        CallbackService,
        CallbackNotesDialogService,
        CategoryReorderDialogService,
        CategorySelectorDialogService,
        ServiceTicketsService,
        DataSharingService,
        ChangeServiceTicketStatusDialogService,
        ServiceTicketFormDialogService,
        CallbackNoteFormDialogService,
        ServiceTicketNoteFormDialogService,
        ClientNextDeliveryTaskService,
        ClientNextDeliveryNoteFormDialogService,
        SalesRepDashboardService,
        TMDashboardService,
        PhoneNumberPrefixService,
        CampaignsService,
        CreditTaskCampaignService,
        ScheduledJobsService,
        ClientNextDeliveryTaskAutomationDialogService,
        RoutesService,
        //PushToOptimorouteDialogService,
        NotificationConfigurationService,
        CircuitOrderService,
        LocalDnclService,
        ScheduleManagementService,
        AddPhoneNumbersDialogService,
        OptimoConfigService,
        BlanketOrderMgmtService,
        ErpIntegrationMgmtService,
        CallCenterConfigurationService,
        BlanketOrderModificationPreviewDialogService,
        DeliveryDateChoiceDialogService
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
