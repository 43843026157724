import { Component, OnInit } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { AccountManageService } from '../services/accountManage.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserConfigurationService } from '../services/user-configuration.service';

declare const $: any;

export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    collapse?: string;
    children?: ChildrenItems[];
    permission?: string;
    permissionExcept?: string;
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
    permission?: string;
}

export const EMPLOYEEROUTES: RouteInfo[] = [
    {
        path: '/employee-profile',
        title: 'SIDEBAR.PROFILE',
        type: 'link',
        icontype: 'dashboard',
    },
    {
        path: '/login',
        title: 'SIDEBAR.SIGNOUT',
        type: 'link',
        icontype: 'clock',
    },
];
export const ROUTES: RouteInfo[] = [
    {
        path: '/dashboard',
        title: 'SIDEBAR.DASHBOARD',
        type: 'link',
        icontype: 'dashboard',
        permission: 'ViewCrmDashboard',
    },
    {
        path: '/client-leads',
        title: 'SIDEBAR.CLIENTLEADS',
        type: 'link',
        icontype: 'assignment_ind',
        permission: 'ViewClientLead',
    },
    {
        path: '/campaigns',
        title: 'SIDEBAR.CAMPAIGNS',
        type: 'link',
        icontype: 'campaign',
        permission: 'ViewCampaign',
    },
    {
        path: '/credit-task-campaigns',
        title: 'SIDEBAR.CREDITTASKCAMPAIGNS',
        type: 'link',
        icontype: 'campaign',
        permission: 'ViewCreditTaskCampaign',
    },
    {
        path: '/client-leads/myleads',
        title: 'SIDEBAR.MYCLIENTLEADS',
        type: 'link',
        icontype: 'assignment_ind',
        permission: 'ViewAssignedClientLeads',
    },
    {
        path: '/callbacks',
        title: 'SIDEBAR.CALLBACKS',
        type: 'link',
        icontype: 'keyboard_return',
        permission: 'ViewCallbacks',
    },
    {
        path: '/callbacks/mycallbacks',
        title: 'SIDEBAR.MYCALLBACKS',
        type: 'link',
        icontype: 'keyboard_return',
        permission: 'ViewAssignedCallbacks',
    },
    {
        path: '/appointments',
        title: 'SIDEBAR.APPOINTMENTS',
        type: 'link',
        icontype: 'local_phone',
        permission: 'ViewAppointment',
    },
    {
        path: '/appointment-management/list',
        title: 'SIDEBAR.APPOINTMENTMANAGEMENT',
        type: 'link',
        icontype: 'event',
        permission: 'ViewAppointmentManagement',
    },
    {
        path: '/sales-rep-appointments',
        title: 'SIDEBAR.ASSIGNEDAPPOINTMENTS',
        type: 'link',
        icontype: 'people',
        permission: 'ViewAssignedAppointments',
    },
    {
        path: '/appointment-qualifications',
        title: 'SIDEBAR.APPOINTMENTQUALIFICATIONS',
        type: 'link',
        icontype: 'rate_review',
        permission: 'ViewAppointmentQualification',
    },
    {
        path: '',
        title: 'SIDEBAR.SCHEDULES',
        type: 'sub',
        icontype: 'event',
        collapse: 'schedules',
        children: [
            {
                path: 'sales-rep-schedule',
                title: 'SIDEBAR.SALESREPSCHEDULE',
                type: 'link',
                ab: 'SR',
                permission: 'ViewSalesRepScheduleInfo',
            },
            {
                path: 'regional-visualization',
                title: 'SIDEBAR.REGIONS',
                type: 'link',
                ab: 'RG',
                permission: 'ViewRegionalVisualization',
            },
        ],
    },
    {
        path: '/products',
        title: 'SIDEBAR.PRODUCTS',
        type: 'link',
        icontype: 'list',
        permission: 'ViewProduct',
    },
    {
        path: '',
        title: 'SIDEBAR.INVENTORY',
        type: 'sub',
        icontype: 'label',
        collapse: 'template',
        children: [
            {
                path: 'categories',
                title: 'SIDEBAR.CATEGORIES',
                type: 'link',
                ab: 'CT',
                permission: 'ViewCategories',
            },
            {
                path: 'menus',
                title: 'SIDEBAR.MENUS',
                type: 'link',
                ab: 'MN',
                permission: 'ViewMenu',
            },
            {
                path: 'productgroups',
                title: 'PRODUCTGROUPS.PRODUCTGROUPS',
                type: 'link',
                ab: 'PG',
                permission: 'ViewProductGroups',
            },
            {
                path: 'referralcatalog',
                title: 'SIDEBAR.REFERRALS',
                type: 'link',
                ab: 'RC',
                permission: 'ViewReferralCatalog',
            },
            {
                path: 'freezers',
                title: 'SIDEBAR.FREEZERS',
                type: 'link',
                ab: 'FR',
                permission: 'ViewFreezers',
            },
            {
                path: 'freezer-regions',
                title: 'SIDEBAR.FREEZERREGIONS',
                type: 'link',
                ab: 'FR',
                permission: 'ViewFreezerRegions',
            },
        ],
    },
    {
        path: '/circuit-order',
        title: 'SIDEBAR.CIRCUIT_ORDER',
        type: 'link',
        icontype: 'low_priority',
        permission: 'ViewCircuitOrder',
    },
    {
        path: '',
        title: 'SIDEBAR.LIVARSION',
        type: 'sub',
        icontype: 'alt_route',
        collapse: 'livarsion_collapse',
        permission: 'ViewRoutes',
        children: [
            {
                path: 'routes',
                title: 'SIDEBAR.ROUTES',
                type: 'link',
                ab: 'RT',
                permission: 'ViewRoutes',
            },
            {
                path: 'optimo-config',
                title: 'SIDEBAR.OPTIMOCONFIG',
                type: 'link',
                ab: 'OC',
                permission: 'ViewOptimoConfig',
            },
        ],
    },
    {
        path: '/order-cancellation-requests',
        title: 'SIDEBAR.CANCELLATIONS',
        type: 'link',
        icontype: 'report',
        permission: 'OrderCancellationRequests',
    },
    {
        path: '/login-page-management',
        title: 'SIDEBAR.LOGINPAGEMANAGEMENT',
        type: 'link',
        icontype: 'view_compact',
        permission: 'EditLoginPage',
    },
    {
        path: '/service-tickets',
        title: 'SIDEBAR.SERVICETICKETS',
        type: 'link',
        icontype: 'support_agent',
        permission: 'ViewServiceTickets',
    },
    {
        path: '/service-tickets/my-tickets',
        title: 'SIDEBAR.SERVICETICKETS',
        type: 'link',
        icontype: 'support_agent',
        permission: 'ViewAssignedServiceTickets',
        permissionExcept: 'ViewServiceTickets',
    },
    {
        path: '/client-next-delivery-task',
        title: 'SIDEBAR.CLIENTNEXTDELIVERYTASK',
        type: 'link',
        icontype: 'timer',
        permission: 'ViewNextDeliveryTask',
    },
    {
        path: '/client-next-delivery-task/my',
        title: 'SIDEBAR.MYCLIENTNEXTDELIVERYTASK',
        type: 'link',
        icontype: 'timer',
        permission: 'ViewAssignedNextDeliveryTask',
    },
    {
        path: '/sales-rep-dashboard',
        title: 'SIDEBAR.SALESREPDASHBOARD',
        type: 'link',
        icontype: 'assessment',
        permission: 'ViewSalesDashboard',
    },
    {
        path: '/tm-dashboard',
        title: 'SIDEBAR.TMDASHBOARD',
        type: 'link',
        icontype: 'assessment',
        permission: 'ViewTMDashboard',
    },
    {
        path: '',
        title: 'SIDEBAR.DNCL',
        type: 'sub',
        collapse: 'dncl_collapse',
        icontype: 'phonelink_erase',
        permission: 'ViewDnclList',
        children: [
            {
                path: 'federal-dncl',
                title: 'SIDEBAR.FEDERALDNCL',
                type: 'link',
                ab: 'FD',
                permission: 'ViewDnclList',
            },
            {
                path: 'local-dncl',
                title: 'SIDEBAR.LOCALDNCL',
                type: 'link',
                ab: 'LD',
                permission: 'ViewLocalDnclList',
            },
        ],
    },
    {
        path: '/powerbi',
        title: 'SIDEBAR.POWERBI',
        type: 'sub',
        icontype: 'equalizer',
        permission: 'ViewPowerBI',
        collapse: 'powerbi_collapse',
        children: [
            {
                path: 'sales-report',
                title: 'SIDEBAR.SALESREPORT',
                type: 'link',
                ab: 'SR',
            },
            {
                path: 'representative-kpi',
                title: 'SIDEBAR.REPRESENTATIVEKPI',
                type: 'link',
                ab: 'RK',
            },
        ],
    },
    {
        path: '/notification-configuration',
        title: 'SIDEBAR.NOTIFICATIONS',
        type: 'link',
        icontype: 'notifications',
        permission: 'EditNotificationConfiguration',
    },
    {
        path: '/erp-blanket-sales-order-management',
        title: 'Blanket Sales Order Management',
        type: 'link',
        icontype: 'dashboard',
        permission: 'ViewERPSalesOrder'
    },

    {
        path: '/erp-export-order-management',
        title: 'ERP Export Order Management',
        type: 'link',
        icontype: 'dashboard',
        permission: 'ViewERPSalesOrder'
    },
    {
        path: '',
        title: 'SIDEBAR.CALL_CENTER_CONFIG',
        type: 'sub',
        icontype: 'support_agent',
        collapse: 'call_center_config_collapse',
        permission: 'EditStatutoryHoliday',
        children: [
            // {
            //     path: 'global-call-center-config',
            //     title: 'SIDEBAR.GLOBAL_CALL_CENTER_CONFIG',
            //     type: 'link',
            //     ab: 'DP',
            //     permission: 'ViewCallCenterConfig',
            // },
            {
                path: 'statutory-holidays',
                title: 'SIDEBAR.STATUTORY_HOLIDAYS',
                type: 'link',
                ab: 'SH',
                permission: 'EditStatutoryHoliday',
            },
            // {
            //     path: 'department-configs',
            //     title: 'SIDEBAR.DEPARTMENT_CONFIGS',
            //     type: 'link',
            //     ab: 'CC',
            //     permission: 'ViewCallCenterConfig',
            // },
        ],
    },
];

@Component({
    selector: 'app-sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})
export class SidebarComponent implements OnInit {
    public menuItems: any[];
    public dropDownMenuItems: any[];

    showFullLogo: boolean;

    constructor(
        public accountService: AccountManageService,
        private translateService: TranslateService,
        private router: Router,
        private userConfigService: UserConfigurationService
    ) { }

    logout() {
        this.accountService.logout();
        this.router.navigate(['/login']);
    }

    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    }

    ngOnInit() {
        this.menuItems = ROUTES.filter((menuItem) => menuItem);
        this.dropDownMenuItems = EMPLOYEEROUTES.filter((menuItem) => menuItem);

        this.setLanguage(this.userConfigService.retrieveConfigValue('language'));
    }

    isSidebarHidden(): boolean {
        return this.userConfigService.retrieveConfigValue('sidebarMinimized');
    }

    updatePS(): void {
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
            const ps = new PerfectScrollbar(elemSidebar, { wheelSpeed: 2, suppressScrollX: true });
        }
    }

    switchLanguage() {
        if (this.translateService.currentLang === 'en') {
            this.setLanguage('fr');
        } else {
            this.setLanguage('en');
        }
    }

    setLanguage(language: string) {
        this.translateService.use(language);
        this.userConfigService.setConfigValue('language', language);
    }

    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }
}
