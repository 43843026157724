import { Injectable } from '@angular/core';
import {
    AppointmentStatus,
    MartialStatus,
    Department,
    LeadNoteType,
    LanguageType,
    AppointmentType,
    AppointmentDispatchType,
    ImportOrderType,
    UnbookableReason,
    ClientLeadStatus,
    ServiceTicketPriority,
    ServiceTicketStatus,
    CallbackUnbookableReason,
    CallbackNotInterestedReason,
    ServiceTicketNoteType,
    ClientNextDeliveryTaskNoteType,
    ClientNextDeliveryTaskStatus,
    CallbackClientLeadStatus,
    FreezerSectors,
    Title,
    CampaignStatus,
    CreditTaskType,
    NotInterestedReason,
    DayOfWeek,
    RouteStatus,
} from './api.services';
import { NgxPermissionsService } from 'ngx-permissions';

export class EnumWrap {
    constructor(public display: string, public value: string, public isHidden = false) {}
}

@Injectable()
export class EnumListService {
    findDisplayName(enumList: EnumWrap[], value): string {
        return enumList.find((enumWrap) => enumWrap.value === value).display;
    }

    public martialStatuses = [
        new EnumWrap('Single', MartialStatus.Single),
        new EnumWrap('Married', MartialStatus.Married),
        new EnumWrap('Divorced', MartialStatus.Divoreced),
        new EnumWrap('Windowed', MartialStatus.Windowed),
        new EnumWrap('Other', MartialStatus.Other),
        new EnumWrap('Prefer not to say', MartialStatus.PreferNotToSay),
    ];

    public languages = [new EnumWrap('LANGUAGES.ENGLISH', LanguageType.English), new EnumWrap('LANGUAGES.FRENCH', LanguageType.French)];

    public clientLeadNoteTypes = [
        new EnumWrap('NOTETYPES.COMMENT', LeadNoteType.Comment),
        new EnumWrap('NOTETYPES.FOLLOWUP', LeadNoteType.FollowUp),
        new EnumWrap('NOTETYPES.APPOINTMENT', LeadNoteType.Appointment),
        new EnumWrap('NOTETYPES.CALL', LeadNoteType.Call),
        new EnumWrap('NOTETYPES.OTHER', LeadNoteType.Other),
        new EnumWrap('NOTETYPES.NOTINTERESTED', LeadNoteType.NotInterested),
        new EnumWrap('NOTETYPES.UNBOOKABLE', LeadNoteType.Unbookable),
    ];

    public callbackClientLeadNoteTypes = [
        new EnumWrap('NOTETYPES.COMMENT', LeadNoteType.Comment),
        new EnumWrap('NOTETYPES.FOLLOWUP', LeadNoteType.FollowUp),
        new EnumWrap('NOTETYPES.NOTINTERESTED', LeadNoteType.NotInterested),
        new EnumWrap('NOTETYPES.UNBOOKABLE', LeadNoteType.Unbookable),
    ];

    public notInterestedReasons = [
        new EnumWrap('NOTINTERESTED.PREFGROCERYACCESS', NotInterestedReason.PrefGroceryAccess),
        new EnumWrap('NOTINTERESTED.SELFPROVISIONING', NotInterestedReason.SelfProvisioning),
        new EnumWrap('NOTINTERESTED.TELEMARKETING', NotInterestedReason.Telemarketing),
        new EnumWrap('NOTINTERESTED.ELDERLY', NotInterestedReason.Elderly),
        new EnumWrap('NOTINTERESTED.LIFESTYLE', NotInterestedReason.Lifestyle),
        new EnumWrap('NOTINTERESTED.COMPETITION', NotInterestedReason.Competition),
        new EnumWrap('NOTINTERESTED.DOESNOTWANTTOMEET', NotInterestedReason.DoesNotWantToMeet),
        new EnumWrap('NOTINTERESTED.UNKNOWNREFERENCE', NotInterestedReason.UnknownReference),
        new EnumWrap('NOTINTERESTED.COMMITMENT', NotInterestedReason.Commitment),
        new EnumWrap('NOTINTERESTED.FREEZER', NotInterestedReason.Freezer),
        new EnumWrap('NOTINTERESTED.DIET', NotInterestedReason.Diet),
        new EnumWrap('NOTINTERESTED.NOTOBTAINED', NotInterestedReason.NotObtained),
        new EnumWrap('NOTINTERESTED.WRONGNUMBERORNOSERVICE', NotInterestedReason.WrongNumberOrNoService),
        new EnumWrap('NOTINTERESTED.EXISTINGCUSTOMER', NotInterestedReason.ExistingCustomer),
    ];

    public unbookableReasons = [
        new EnumWrap('UNBOOKABLEREASON.CORPORATE', UnbookableReason.Corporate),
        new EnumWrap('UNBOOKABLEREASON.MEDICAL', UnbookableReason.Medical),
        new EnumWrap('UNBOOKABLEREASON.PROFESSIONAL', UnbookableReason.Professional),
        new EnumWrap('UNBOOKABLEREASON.COMMERCIAL', UnbookableReason.Commercial),
        new EnumWrap('UNBOOKABLEREASON.BADNUMBER', UnbookableReason.BadNumber),
    ];

    public callbackUnbookableReasons = [
        new EnumWrap('CALLBACKUNBOOKABLEREASON.DIVORCED', CallbackUnbookableReason.Divorced),
        new EnumWrap('CALLBACKUNBOOKABLEREASON.MOVED', CallbackUnbookableReason.Moved),
        new EnumWrap('CALLBACKUNBOOKABLEREASON.LOSTTOCOMPETITION', CallbackUnbookableReason.LostToCompetition),
        new EnumWrap('CALLBACKUNBOOKABLEREASON.VEGETARIANS', CallbackUnbookableReason.Vegetarians),
        new EnumWrap('CALLBACKUNBOOKABLEREASON.MORESERVICE', CallbackUnbookableReason.MoreService),
        new EnumWrap('CALLBACKUNBOOKABLEREASON.FARMERSORHUNTERS', CallbackUnbookableReason.FarmersOrHunters),
        new EnumWrap('CALLBACKUNBOOKABLEREASON.RENEWALALREADYDONE', CallbackUnbookableReason.RenewalAlreadyDone),
        new EnumWrap('CALLBACKUNBOOKABLEREASON.BLOCKEDONCREDIT', CallbackUnbookableReason.BlockedOnCredit),
        new EnumWrap('CALLBACKUNBOOKABLEREASON.DEATH', CallbackUnbookableReason.Death),
    ];

    public callbackNotInterestedReasons = [
        new EnumWrap('CALLBACKNOTINTERESTEDREASON.NOTSATISFIEDWITHQUALITY', CallbackNotInterestedReason.NotSatisfiedWithQuality),
        new EnumWrap('CALLBACKNOTINTERESTEDREASON.NOTSATISFIEDWITHSERVICE', CallbackNotInterestedReason.NotSatisfiedWithService),
        new EnumWrap('CALLBACKNOTINTERESTEDREASON.TIGHTFINANCES', CallbackNotInterestedReason.TightFinances),
        new EnumWrap('CALLBACKNOTINTERESTEDREASON.TOOEXPENSIVE', CallbackNotInterestedReason.TooExpensive),
        new EnumWrap('CALLBACKNOTINTERESTEDREASON.TOOMUCHONSTOCK', CallbackNotInterestedReason.TooMuchOnStock),
        new EnumWrap('CALLBACKNOTINTERESTEDREASON.ENCOURAGESLOCAL', CallbackNotInterestedReason.EncouragesTheLocal),
        new EnumWrap('CALLBACKNOTINTERESTEDREASON.NOTENOUGHECOLOGICAL', CallbackNotInterestedReason.NotEnoughEcological),
        new EnumWrap('CALLBACKNOTINTERESTEDREASON.WANTNOFOLLOWUP', CallbackNotInterestedReason.TooMuchOnStock),
    ];

    public productDepartments = [
        new EnumWrap('PRODUCT.DEPARTMENTS.EPICERIE', Department.Epicerie),
        new EnumWrap('PRODUCT.DEPARTMENTS.SURGELE', Department.Surgele),
        new EnumWrap('PRODUCT.DEPARTMENTS.VDIVERS', Department.VDivers),
        new EnumWrap('PRODUCT.DEPARTMENTS.VRIENDEAU', Department.VRiendeau),
        new EnumWrap('PRODUCT.DEPARTMENTS.VIANDE', Department.Viande),
    ];

    public appointmentTypes = [
        new EnumWrap('APPOINTMENTTYPES.NEW', AppointmentType.New),
        new EnumWrap('APPOINTMENTTYPES.RENEW', AppointmentType.Renew),
        new EnumWrap('APPOINTMENTTYPES.WINBACK', AppointmentType.Winback),
    ];

    public appointmentStatuses = [
        new EnumWrap('APPOINTMENTSTATUS.STANDBY', AppointmentStatus.OnStandby),
        new EnumWrap('APPOINTMENTSTATUS.CONFIRMED', AppointmentStatus.Confirmed),
        new EnumWrap('APPOINTMENTSTATUS.POSTPONED', AppointmentStatus.Postponed),
        new EnumWrap('APPOINTMENTSTATUS.SOLD', AppointmentStatus.Sold),
        new EnumWrap('APPOINTMENTSTATUS.CANCELLED', AppointmentStatus.Cancelled),
        new EnumWrap('APPOINTMENTSTATUS.DELIVERED', AppointmentStatus.Delivered),
        new EnumWrap('APPOINTMENTSTATUS.CALLBACK', AppointmentStatus.Callback),
        new EnumWrap('APPOINTMENTSTATUS.SHOWNNOTSOLD', AppointmentStatus.ShownNotSold),
        new EnumWrap('APPOINTMENTSTATUS.SHOWNTOBEFINALIZED', AppointmentStatus.ShownToBeFinalized),
        new EnumWrap('APPOINTMENTSTATUS.NOTSHOWN', AppointmentStatus.NotShown),
        new EnumWrap('APPOINTMENTSTATUS.REFUSED', AppointmentStatus.Refused),
    ];

    public appointmentDispatchType = [
        new EnumWrap('APPOINTMENTDISPATCH.DISPATCH', AppointmentDispatchType.Dispatch),
        new EnumWrap('APPOINTMENTDISPATCH.RECALLDISPATCHED', AppointmentDispatchType.RecallDispatched),
        new EnumWrap('APPOINTMENTDISPATCH.UPDATEDISPATCHED', AppointmentDispatchType.UpdateDispatched),
    ];

    public orderImportType = [
        new EnumWrap('IMPORTEDORDERTYPE.NEW', ImportOrderType.New),
        new EnumWrap('IMPORTEDORDERTYPE.RENEW', ImportOrderType.Renew),
        new EnumWrap('IMPORTEDORDERTYPE.CUSTOM', ImportOrderType.Custom),
    ];

    public clientLeadStatuses = [
        new EnumWrap('CLIENTLEADSTATUS.CLOSED', ClientLeadStatus.Closed),
        new EnumWrap('CLIENTLEADSTATUS.NOTINTERESTED', ClientLeadStatus.NotInterested),
        new EnumWrap('CLIENTLEADSTATUS.OPEN', ClientLeadStatus.Open),
        new EnumWrap('CLIENTLEADSTATUS.PULLED', ClientLeadStatus.Pulled),
        new EnumWrap('CLIENTLEADSTATUS.UNBOOKABLE', ClientLeadStatus.Unbookable),
        new EnumWrap('CLIENTLEADSTATUS.RETIRED', ClientLeadStatus.Retired),
    ];

    public serviceTicketStatuses = [
        new EnumWrap('SERVICETICKETSTATUS.PENDINGASSIGNMENT', ServiceTicketStatus.PendingAssignment),
        new EnumWrap('SERVICETICKETSTATUS.INPROGRESS', ServiceTicketStatus.InProgress),
        new EnumWrap('SERVICETICKETSTATUS.RESOLVED', ServiceTicketStatus.Resolved),
        new EnumWrap('SERVICETICKETSTATUS.CANCELED', ServiceTicketStatus.Canceled),
        new EnumWrap('SERVICETICKETSTATUS.EXPIRED', ServiceTicketStatus.Expired),
        new EnumWrap('SERVICETICKETSTATUS.ASSIGNED', ServiceTicketStatus.Assigned),
        new EnumWrap('SERVICETICKETSTATUS.WORKINGON', ServiceTicketStatus.WorkingOn),
        new EnumWrap('SERVICETICKETSTATUS.CONTACTEDPARTNER', ServiceTicketStatus.ContactedPartner),
        new EnumWrap('SERVICETICKETSTATUS.WONTFIX', ServiceTicketStatus.WontFix),
        new EnumWrap('SERVICETICKETSTATUS.PENDINGAPPROVAL', ServiceTicketStatus.PendingApproval),
    ];

    public serviceTicketPriorities = [
        new EnumWrap('SERVICETICKETPRIORITY.UNKNOWN', ServiceTicketPriority.Unknown),
        new EnumWrap('SERVICETICKETPRIORITY.LOW', ServiceTicketPriority.Low),
        new EnumWrap('SERVICETICKETPRIORITY.MEDIUM', ServiceTicketPriority.Medium),
        new EnumWrap('SERVICETICKETPRIORITY.HIGH', ServiceTicketPriority.High),
        new EnumWrap('SERVICETICKETPRIORITY.URGENT', ServiceTicketPriority.Urgent),
        new EnumWrap('SERVICETICKETPRIORITY.REQUEST', ServiceTicketPriority.Request),
    ];

    public serviceTicketNoteTypes = [
        new EnumWrap('SERVICETICKETNOTETYPE.COMMENT', ServiceTicketNoteType.Comment),
        new EnumWrap('SERVICETICKETNOTETYPE.STATUSCHANGED', ServiceTicketNoteType.StatusChange, true),
        new EnumWrap('SERVICETICKETNOTETYPE.TICKETUPDATED', ServiceTicketNoteType.TicketUpdated, true),
    ];

    public clientNextDeliveryTaskNoteTypes = [
        new EnumWrap('NOTETYPES.COMMENT', ClientNextDeliveryTaskNoteType.Comment),
        new EnumWrap('NOTETYPES.FOLLOWUP', ClientNextDeliveryTaskNoteType.FollowUp),
    ];

    public clientNextDeliveryTaskStatuses = [
        new EnumWrap('CLIENTNEXTDELIVERYTASKSTATUS.CLOSED', ClientNextDeliveryTaskStatus.Closed),
        new EnumWrap('CLIENTNEXTDELIVERYTASKSTATUS.OPEN', ClientNextDeliveryTaskStatus.Open),
        new EnumWrap('CLIENTNEXTDELIVERYTASKSTATUS.STANDBY', ClientNextDeliveryTaskStatus.StandBy),
        new EnumWrap('CLIENTNEXTDELIVERYTASKSTATUS.AUTOMATEDCLOSED', ClientNextDeliveryTaskStatus.AutomatedClosed),
    ];

    public callbackClientLeadStatuses = [
        new EnumWrap('CALLBACKCLIENTLEADSTATUS.CLOSED', CallbackClientLeadStatus.Closed),
        new EnumWrap('CALLBACKCLIENTLEADSTATUS.OPEN', CallbackClientLeadStatus.Open),
        new EnumWrap('CALLBACKCLIENTLEADSTATUS.NOTINTERESTED', CallbackClientLeadStatus.NotInterested),
        new EnumWrap('CALLBACKCLIENTLEADSTATUS.UNBOOKABLE', CallbackClientLeadStatus.Unbookable),
        new EnumWrap('CALLBACKCLIENTLEADSTATUS.AUTOMATEDCLOSED', CallbackClientLeadStatus.AutomatedClosed),
    ];

    public freezerSectors = [
        new EnumWrap('Abitibi', FreezerSectors.Abitibi),
        new EnumWrap('Cote-Nord', FreezerSectors.CoteNord),
        new EnumWrap('New Brunswick', FreezerSectors.NewBrunswick),
        new EnumWrap('Montreal', FreezerSectors.Montreal),
        new EnumWrap('Quebec', FreezerSectors.Quebec),
        new EnumWrap('Ottawa And Gatineau', FreezerSectors.OttawaAndGatineau),
        new EnumWrap('Gaspesie And BasStlaurent', FreezerSectors.GaspesieAndBasStlaurent),
        new EnumWrap('Saguenay', FreezerSectors.Saguenay),
    ];

    public campaignStatuses = [new EnumWrap('CAMPAIGNS.CAMPAIGNSTATUS.OPEN', CampaignStatus.Open), new EnumWrap('CAMPAIGNS.CAMPAIGNSTATUS.CLOSED', CampaignStatus.Closed)];

    public titles = [new EnumWrap('None', Title.None), new EnumWrap('Ms', Title.Ms), new EnumWrap('Mr', Title.Mr)];

    public creditTaskTypes = [
        new EnumWrap('Collections', CreditTaskType.Collections),
        new EnumWrap('Locked Contract', CreditTaskType.LockedContract),
        new EnumWrap('NSF', CreditTaskType.Nsf),
        new EnumWrap('Failed Payment', CreditTaskType.FailedPayment),
        new EnumWrap('Missing BankInfo', CreditTaskType.MissingBankInfo),
    ];

    public routeStatuses = [
        new EnumWrap('Planned', RouteStatus.Planned),
        new EnumWrap('Removed from optimoroute', RouteStatus.RemovedFromOptimoRoute),
        new EnumWrap('RePlanned', RouteStatus.RePlanned),
    ];

    public petTypes = [
        new EnumWrap('APPOINTMENT.PETS.DOG', 'petTypeDog'),
        new EnumWrap('APPOINTMENT.PETS.CAT', 'petTypeCat'),
        new EnumWrap('APPOINTMENT.PETS.FISH', 'petTypeFish'),
        new EnumWrap('APPOINTMENT.PETS.BIRD', 'petTypeBird'),
        new EnumWrap('APPOINTMENT.PETS.RODENT', 'petTypeRodent'),
        new EnumWrap('APPOINTMENT.PETS.REPTILE', 'petTypeReptile'),
        new EnumWrap('APPOINTMENT.PETS.RABBIT', 'petTypeRabbit'),
        new EnumWrap('APPOINTMENT.PETS.OTHER', 'petTypeOtherOrExotic'),
    ];

    constructor(private permissionService: NgxPermissionsService) {
        if (this.permissionService.hasPermission('HasClientNextDeliveryTaskNoteTypeStandBy')) {
            this.clientNextDeliveryTaskNoteTypes.push(new EnumWrap('NOTETYPES.STANDBY', ClientNextDeliveryTaskNoteType.StandBy));
        }
    }
}
