import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { BlanketOrder, SalesOrderInventoryItem } from 'src/app/admin-pages/erp-blanket-sales-order-management/erp-blanket-sales-order-management.data'

@Component({
  selector: 'app-blanket-order-modification-preview-dialog',
  templateUrl: './blanket-order-modification-preview-dialog.component.html',
  styleUrls: ['./blanket-order-modification-preview-dialog.component.scss']
})
export class BlanketOrderModificationPreviewDialogComponent implements OnInit {

  originalBlanketOrder: BlanketOrder
  modifiedBlanketOrder: BlanketOrder

  changes: BlanketOrderChanges = {
    addedItems: [],
    removedItems: [],
    modifiedItems: []
  }

  constructor(
    private dialogRef: MatDialogRef<BlanketOrderModificationPreviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { originalBlanketOrder: BlanketOrder, modifiedBlanketOrder: BlanketOrder }
  ) {
    if (this.data) {
      this.originalBlanketOrder = this.data.originalBlanketOrder
      this.modifiedBlanketOrder = this.data.modifiedBlanketOrder
    }
  }

  ngOnInit(): void {
    this.compareBlanketOrders()
  }

  private compareBlanketOrders() {
    const originalItems = this.originalBlanketOrder.inventoryItemsTotal || []
    const modifiedItems = this.modifiedBlanketOrder.inventoryItemsTotal || []

    this.changes.addedItems = modifiedItems.filter(item =>
      !originalItems.some(i => i.itemNo === item.itemNo)
    )

    this.changes.removedItems = originalItems.filter(item =>
      !modifiedItems.some(i => i.itemNo === item.itemNo)
    )

    this.changes.modifiedItems = modifiedItems
      .map(item => {
        const originalItem = originalItems.find(i => i.itemNo === item.itemNo)
        if (originalItem && (originalItem.quantity !== item.quantity || originalItem.unitPrice !== item.unitPrice)) {
          return {
            itemNo: item.itemNo,
            displayName: item.displayName,
            quantityBefore: originalItem.quantity,
            quantityAfter: item.quantity,
            unitPriceBefore: originalItem.unitPrice,
            unitPriceAfter: item.unitPrice
          }
        }
      })
      .filter(item => item !== undefined) as SalesOrderInventoryModifiedItem[]

    this.changes.totalQuantityBefore = originalItems.reduce((acc, item) => acc + item.quantity, 0)
    this.changes.totalQuantityAfter = modifiedItems.reduce((acc, item) => acc + item.quantity, 0)

    this.changes.totalCostBefore = this.originalBlanketOrder.orderTotal
    this.changes.totalCostAfter = this.modifiedBlanketOrder.orderTotal
  }

  closeDialog(result: boolean) {
    this.dialogRef.close(result)
  }
}

export interface BlanketOrderChanges {
  addedItems: SalesOrderInventoryItem[]
  removedItems: SalesOrderInventoryItem[]
  modifiedItems: SalesOrderInventoryModifiedItem[]
  totalQuantityBefore?: number
  totalQuantityAfter?: number
  totalCostBefore?: number
  totalCostAfter?: number
}

export interface SalesOrderInventoryModifiedItem {
  itemNo: string
  displayName: string
  quantityBefore: number
  quantityAfter: number
  unitPriceBefore: number
  unitPriceAfter: number
}